import Notifications from 'Shared/Notifications/notifications';

const initialState = {
   displayMobileSearch: false,
   displayMobileMenu: false,
   userTopics: null,
   notifications: new Notifications(),
};

const headerReducer = (state = initialState, action) => {
   switch (action.type) {
      case 'TOGGLE_MOBILE_SEARCH':
         return {
            ...state,
            displayMobileSearch: action.value,
         };
      case 'UPDATE_NOTIFICATIONS':
         return {
            ...state,
            notifications: action.notifications,
         };
      case 'TOGGLE_MOBILE_MENU':
         return {
            ...state,
            displayMobileMenu: !state.displayMobileMenu,
         };
      default:
         return state;
   }
};

export default headerReducer;
