import {
   IndividualDetailsActionType,
   IndividualDetailsErrorAction,
   SetIndividualDetailsAction,
} from './individual-details.actions';
import { NewAssignmentCreatedAction, SharedActionType } from './shared.actions';

export interface IndividualDetailsState {
   [userid: number]: IndividualDetails;
}

export interface IndividualDetails {
   courses: any[] | null;
   error?: any;
}

const initialState: IndividualDetailsState = {};

type Actions =
   | SetIndividualDetailsAction
   | IndividualDetailsErrorAction
   | NewAssignmentCreatedAction;

export const individualDetailsReducer = (
   state: IndividualDetailsState = initialState,
   action: Actions
): IndividualDetailsState => {
   switch (action.type) {
      case IndividualDetailsActionType.SET_INDIVIDUAL_DETAILS: {
         return {
            ...state,
            [action.userid]: {
               courses: action.courses,
               error: null,
            },
         };
      }
      case IndividualDetailsActionType.INDIVIDUAL_DETAILS_ERROR: {
         return {
            ...state,
            [action.userid]: {
               courses: [],
               error: action.error,
            },
         };
      }
      case SharedActionType.NEW_ASSIGMENT_CREATED: {
         if (!action.assignment.assignee_userid) {
            return state;
         }

         const users = { ...state };
         // Remove changed user so it can refresh
         delete users[action.assignment.assignee_userid];
         return users;
      }
      default: {
         return state;
      }
   }
};

export const INDIVIDUAL_DETAILS_STATE_ROOT = 'individualDetailsState';

export const individualDetailsSelector = (userid: number): ((state) => IndividualDetails) => {
   return state => {
      const individualDetailsState: IndividualDetailsState = state[INDIVIDUAL_DETAILS_STATE_ROOT];
      return individualDetailsState[userid] || { courses: null };
   };
};
