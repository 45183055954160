export default class Notification {
   constructor(data, markReadDispatch) {
      this.signature = data.signature;
      this.metadata = data.metadata;
      this.read = data.read;
      this._markReadDispatch = markReadDispatch;
   }

   // Only marks property as read; does not update Redux
   setRead = (read = true) => {
      this.read = read;
   };

   markRead = () => {
      this._markReadDispatch(this);
   };
}
