import Notification from './notification';

const noop = () => {};

export default class Notifications {
   constructor(
      markReadHandler = noop,
      markAllReadHandler = noop,
      notifications = [],
      unreadCount = 0
   ) {
      this._markReadHandler = markReadHandler;
      this._markAllReadHandler = markAllReadHandler;
      this._notifications = notifications;
      this._unreadCount = unreadCount;
   }

   getNotifications() {
      return this._notifications;
   }

   getUnreadNotifications = () => {
      return this._notifications.filter(notification => !notification.read);
   };

   // Note: this is not necessarily the same as getUnreadNotifications().length
   // since we limit the amount of notifications to 10 on the backend
   getUnreadCount = () => this._unreadCount;

   // Shallow copy of actual list
   clone() {
      return new Notifications(
         this._markReadHandler,
         this._markAllReadHandler,
         Array.convert(this._notifications),
         this._unreadCount
      );
   }

   // Only modifies notifications read property; does not update Redux
   setAllNotificationsAsRead() {
      this._notifications.forEach(notification => {
         notification.setRead();
      });

      this._unreadCount = 0;
   }

   markAllRead = () => {
      this._markAllReadHandler();
   };

   setNotificationAsRead = signature => {
      const notification = this._notifications.find(
         notification => notification.signature === signature
      );
      notification && notification.setRead();

      this._unreadCount--;
   };

   addNewNotification = notificationData => {
      this._notifications.unshift(new Notification(notificationData, this._markReadHandler));

      this._unreadCount++;
   };

   initializeNotifications = (notifications, unreadCount) => {
      this._notifications = notifications.map(
         notificationData => new Notification(notificationData, this._markReadHandler)
      );

      this._unreadCount = unreadCount;
   };
}
