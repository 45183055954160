import { PUSH_BANNER, POP_BANNER } from './constants';

const initialState = {
   bannerStack: [],
};

const bannerReducer = (state = initialState, action) => {
   switch (action.type) {
      case PUSH_BANNER():
         return {
            ...state,
            bannerStack: [...state.bannerStack, action.banner],
         };
      case POP_BANNER():
         return {
            ...state,
            bannerStack: state.bannerStack.slice(0, -1),
         };
      default:
         return state;
   }
};

export default bannerReducer;
