import { CSRF } from 'Shared/csrf';
import LoadingIndicator from 'Shared/loading_indicator';
import * as Sentry from '@sentry/browser';

/**
 * @param endpoint: This should be look like "instances/123". Does not include "/api/2.0/".
 * Options are:
 * onSuccess: function(response) {} Function to be called on success. Response will be an object.
 * method: One of {get, post, put, patch, delete}
 * timeout: ms until request is cancelled.
 *
 * send with .send(data);
 * data will be converted to json and sent in the request body.
 *
 * Example:
 * new Request.API_2_0('badges', {
 *  method: 'get',
 *  onSuccess: function(response) { }
 * }).send();
 */
export default Request.API_2_0 = new Class({
   Implements: [Options, Events],

   options: {
      timeout: 10000,
      method: 'get',
      link: 'chain',
      data: '',
      statusPanelMessage: false,
   },

   initialize: function (endpoint, options) {
      this.setOptions(options);

      let self = this;

      let requestMethod = this.options.method.toUpperCase();
      let requestHeaders = {};
      if (requestMethod !== 'GET') {
         this._csrf_check = true;
      }

      // Add a '/' if the endpoint doesn't already have one.
      if (endpoint && endpoint.charAt(0) !== '/') {
         endpoint = '/' + endpoint;
      }

      let uri = new URI('/api/2.0' + endpoint);
      if (App.apiUrlParams && Object.keys(App.apiUrlParams).length) {
         uri.setData(App.apiUrlParams, /* merge with existing */ true);
      }

      if (this.options.queryParams) {
         uri.setData(this.options.queryParams, /* merge with existing */ true);
      }

      // We used to force HTTPS here, but to support IE8, we now allow HTTP.
      // That said, we need to add the X-ALLOW-HTTP header later to make
      // this work.
      this.request = new Request({
         url: uri,
         method: requestMethod,
         headers: requestHeaders,
         link: this.options.link,
         data: this.options.data,
         emulation: false,
         onSuccess: this.onSuccess.bind(this),
         onFailure: function (xhr) {
            let message = self.getErrorMessage(xhr);
            self.fireEvent('failure', [xhr, message]);
            self._reject(message);
         },
         onCancel: function () {
            Request.activeRequests.delete(self);
            self.fireEvent('cancel', arguments);
            self._reject();
         },
         onComplete: function () {
            Request.activeRequests.delete(self);
            Request.completedRequests++;
            self.fireEvent('complete', arguments);
         },
         withCredentials: true,
      });
   },

   send: function (data) {
      let _this = this;

      const promise = new Promise(function (resolve, reject) {
         _this._resolve = resolve;
         _this._reject = reject;

         getRequestHeaders(_this._csrf_check).forEach(header =>
            _this.request.setHeader(header.key, header.value)
         );

         Request.activeRequests.set(_this, {
            url: _this.request.options.url,
            method: _this.options.method,
            data: data,
         });

         if (_this.options.method == 'get') {
            _this.request.send(data);
         } else {
            // Send data as a json blob.
            _this.request.send(JSON.stringify(data));
         }
      });
      if (this.options.statusPanelMessage) {
         LoadingIndicator.withPromise(promise, {
            pendingMessage: this.options.statusPanelMessage,
            failureMessage: _js('Error'),
            hideSuccessMessage: true,
            useErrorResponseAsFailureMessage: true,
         });
      }
      return promise;
   },

   getErrorMessage: function (xhr) {
      let message = this.getErrorMessageByStatusCode(xhr.status);

      if (!message) {
         let response = {};
         try {
            response = JSON.parse(xhr.response);
         } catch (err) {
            // Do not report 400-level errors
            if (xhr.status < 400 || xhr.status >= 500) {
               this.reportToSentry(err, xhr);
            }
         }

         let defaultMessage = _js('An unknown error occurred.');

         if (typeof response == 'string') {
            defaultMessage = response;
         } else if (response.message) {
            defaultMessage = response.message;
         }

         // Use the first error message if one exists or a default message.
         message = response.errors ? response.errors[0].message : defaultMessage;
      }

      return message;
   },

   getErrorMessageByStatusCode: function (statusCode) {
      let messages = {
         409: _js(
            'Your changes could not be saved because the version of the document you are editing is out of date. Reload the page and try again.'
         ),
         429: _js('You have made too many requests recently.'),
      };

      return messages[statusCode];
   },

   onSuccess: function (text) {
      let response;
      try {
         if (!text || typeOf(text) != 'string') {
            response = null;
         } else {
            response = JSON.parse(text);
         }
      } catch (e) {
         // Make sure this try/catch doesn't affect behavior. We still want
         // this to be an unhandled exception (I think). It's certainly easier
         // in development when you get a big red banner.
         this.reportToSentry(e, this.request.xhr);
         this._reject();
         throw e;
      }

      if (response && response.code >= 400) {
         this.fireEvent('onError', [response]);
         this._reject(response);
         if (window.console && console.error) {
            console.error(response.message);
         }
      } else {
         this.fireEvent('onSuccess', [response]);
         this._resolve(response);
      }
   },

   reportToSentry: function (err, xhr) {
      Sentry.captureException(err, {
         contexts: {
            api_response: {
               snippet: xhr.response.substring(0, 200),
               length: xhr.response.length,
            },
            api_request: {
               url: xhr.responseURL,
               status: xhr.status,
               method: this.options.method,
            },
         },
      });
   },
});

export function getRequestHeaders(includeCsrfCheck) {
   const headers = [
      // This enables the API over HTTP so browsers without CORS can use it.
      { key: 'X-ALLOW-HTTP', value: true },
      // Identifies _this request for API stat purposes.
      { key: 'Api-Client', value: 'iFixit-Web' },
   ];
   if (includeCsrfCheck) {
      headers.push({ key: 'X-CSRF', value: CSRF.get() });
   }

   return headers;
}
