import { Action } from 'redux';

export enum SharedActionType {
   NEW_ASSIGMENT_CREATED = 'NEW_ASSIGMENT_CREATED',
}

interface CourseAssignment {
   assignee_teamid?: number;
   assignee_userid?: number;
}

export interface NewAssignmentCreatedAction extends Action {
   type: typeof SharedActionType.NEW_ASSIGMENT_CREATED;
   assignment: CourseAssignment;
}

export const newAssignmentCreated = (assignment: CourseAssignment): NewAssignmentCreatedAction => ({
   type: SharedActionType.NEW_ASSIGMENT_CREATED,
   assignment,
});
