import { Reducer, Store } from 'redux';
import { useSelector } from 'react-redux';
import { RootState } from 'Shared/ifixit_store';

type Selector<T> = (state: RootState) => T;
type Setter<T> = (store: Store) => (value: T) => void;
type Hook<T> = () => [T, Setter<T>];

interface SharedState<T> {
   name: string;
   reducer: Reducer<T>;
   selector: Selector<T>;
   setter: Setter<T>;
   hook: Hook<T>;
}

/**
 * Generates a reducer, selector, setter (combined action
 * creation/dispatch), and a convenient hook for a set-only piece of
 * state.
 */
export function createSharedState<T>(
   name: string,
   initialValue: T = null,
   selector: Selector<T> = null
): SharedState<T> {
   const actionName = 'set' + name;
   const reducer = (state = initialValue, action) => {
      if (action.type === actionName) {
         return action.value;
      } else {
         return state;
      }
   };

   const defaultSelector = (state: RootState) => state[name];
   const localSelector: Selector<T> = selector === null ? defaultSelector : selector;
   const setter: Setter<T> = (store: Store) => (value: T) =>
      store.dispatch({ type: actionName, value: value });
   const hook: Hook<T> = () => [useSelector(localSelector), setter];

   return {
      name,
      reducer,
      selector: localSelector,
      setter,
      hook,
   };
}
